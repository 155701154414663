.img_achievement_icon_1{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -650px -0px;}
.img_achievement_icon_10{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -390px -510px;}
.img_achievement_icon_11{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -390px -340px;}
.img_achievement_icon_12{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -390px -170px;}
.img_achievement_icon_13{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -780px -170px;}
.img_achievement_icon_14{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -260px -850px;}
.img_achievement_icon_15{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -260px -680px;}
.img_achievement_icon_16{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -260px -510px;}
.img_achievement_icon_17{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -260px -340px;}
.img_achievement_icon_18{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -260px -170px;}
.img_achievement_icon_19{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -260px -0px;}
.img_achievement_icon_2{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -520px -850px;}
.img_achievement_icon_20{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -130px -850px;}
.img_achievement_icon_21{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -130px -680px;}
.img_achievement_icon_22{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -130px -510px;}
.img_achievement_icon_23{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -130px -340px;}
.img_achievement_icon_24{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -130px -170px;}
.img_achievement_icon_25{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -130px -0px;}
.img_achievement_icon_26{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -0px -850px;}
.img_achievement_icon_27{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -0px -680px;}
.img_achievement_icon_28{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -0px -510px;}
.img_achievement_icon_29{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -0px -340px;}
.img_achievement_icon_3{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -520px -680px;}
.img_achievement_icon_30{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -0px -170px;}
.img_achievement_icon_31{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -0px -0px;}
.img_achievement_icon_32{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -390px -0px;}
.img_achievement_icon_33{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -780px -0px;}
.img_achievement_icon_34{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -650px -850px;}
.img_achievement_icon_35{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -650px -680px;}
.img_achievement_icon_36{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -650px -510px;}
.img_achievement_icon_37{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -650px -340px;}
.img_achievement_icon_38{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -650px -170px;}
.img_achievement_icon_4{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -520px -510px;}
.img_achievement_icon_5{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -520px -340px;}
.img_achievement_icon_6{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -520px -170px;}
.img_achievement_icon_7{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -520px -0px;}
.img_achievement_icon_8{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -390px -850px;}
.img_achievement_icon_9{top:0px;left:0px;
width:130px;height:170px;background: url("sprites.png") no-repeat -390px -680px;}
