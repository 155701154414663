

/*联系我们主体*/
#Contact {
    /*margin-top: 64px;*/
    /*height: 725px;*/
    /*height: 80vh;*/
    width: 100%;
    background: #f6f6f6;
}

/*联系我们实际容器*/
/*.contact-container {*/
    /*width: 80%;*/
    /*margin: 0 auto;*/
    /*height: 100%;*/
    /*background: white;*/
/*}*/

/*公司地址*/
.contact-company-address {
    /*width: 80%;*/
    /*background: red;*/
    margin: 0 auto;
    padding-top: 20px;
}

/*联系方式*/
.contact-ways{
    /*width: 80%;*/
    /*background: #61dafb;*/
    /*height: 50%;*/
    margin: 20px auto;
    /*position: absolute;*/
    padding-bottom: 20px;
}

/*公司地址标题*/
.contact-company-address-title {
    border-left: 5px solid orange;
    font-size: 20px;
    font-weight: bold;
    color: black;
    padding-left: 8px;
    /*background: green;*/
}

/*公司地址*/
.contact-company-address-left {
    width: 45%;
    float: left;
    background: #888888;
    /*height: 90%;*/
    /*padding-bottom: 90%;*/
    /*height: 0;*/
    height: auto;
    min-height: 200px;
    /*height: 20vw;*/
    margin-left: 10px;
}

/*公司地址*/
.contact-company-address-right {
    width: 100%;
    height: 100%;
    /*height: auto;*/
    /*width: 95%;*/
    /*height: auto;*/
    /*height: 300px;*/
    /*background: pink;*/
    /*height: 90%;*/
    /*padding-bottom: 90%;*/
    /*height: 0;*/
    /*min-height: 200px;*/
    /*margin-left: 5%;*/
    padding-left: 10px;
    padding-top: 10px;

    min-height: 3.0rem;
    max-height: 3.5rem;
}

@media screen and (max-width: 768px) {
    .contact-company-address-right {
        min-height: 4rem;
    }
}

/*公司地址图片*/
.contact-company-address-left-image {
    width: 100%;
    /*height: 95%;*/
    height: auto;
    /*padding: 40px*/
    padding-left: 10px;
    padding-top: 10px;

    min-height: 3.0rem;
    /*max-height: 3.5rem;*/
}

/*联系方式图片*/
.contact-company-ways-image {
    /*margin: 0 auto;*/
    /*text-align: center;*/
    /*margin-left: 5%;*/
    /*position: absolute;*/
    /*left: 50%;*/
    /*margin-top: 20px;*/
    /*margin-left: 20px;*/
    /*padding: 0 auto;*/
    padding-top: 20px;
    width: 100%
    /*height: 100px;*/
    /*background: url("./images/img_contact_ways.png");*/
}

.contact-company-ways-text {
    text-align: center;
    color: #fc8a2f;
    font-size: 0.2rem;
    font-weight: bold;
    text-indent: 1rem;
}