
/*游戏小卡片*/
.product-info-box {
    border-radius: 8px;
    cursor: pointer;
    /*position: relative;*/
    overflow: hidden;   /*超出部分隐藏*/
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    /*max-height: calc(100% - 80px);*/
    /*height: calc(100% - 80px);*/
    /*border: 1px solid gray;*/
    /*height: 300px;*/
    /*height: auto;*/
    max-height: 3rem;
    /*position: relative;*/
    /*clear: both;*/
}

.product-info-box .ant-spin-container {
    height: 100%;
}

.product-info-image-bg {
    width: 100%;
    height: 100%;
    /*max-height: 200px;*/
    /*max-height: 220px;*/
    border-radius: 8px 8px 0 0;
    background-size: 100% 100%;
    /*background: no-repeat center center;*/
}

/* 游戏信息 */
.product-game-info {
    width: 100%;
    background: #fff;
    position: relative;
    transition: all .2s ease;
    /*bottom: 50px;*/
    bottom: 0;
}

/*鼠标移上去时，*/
.product-info-box:hover .product-game-info {
    /*bottom: -20px;*/
    /*height: 300px;*/
    /*bottom: 50%;*/
    /*padding-bottom: 100%*/
    /*margin-bottom: -30%*/
    /*bottom: 200px;*/
    bottom: 0.85rem;
}
/*.product-info-box:hover .product-game-hide-info {*/
    /*!*display: block;*!*/
    /*!*height: 100px;*!*/
    /*!*bottom: 0;*!*/
/*}*/

/*鼠标移上去 图片毛玻璃效果*/
.product-info-box:hover .product-info-image-bg {
    /*-moz-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);*/
    /*-ms-filter: progid:DXImageTransform.Microsoft.Blur(3px);*/
    /*filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);*/
    filter: blur(3px);
}

/*游戏Logo图*/
.product-game-info-logo {
    position: relative;
    height: auto;
    left: 5%;
    max-width: 100%;
    width: 30%;
    margin-top: -20%;
}

/*游戏名字标题*/
.product-game-info-title {
    position: relative;
    left: 5%;
    font-size: 0.24rem;
    text-align: left;
    line-height: 0.3rem;
    color: black;
}

/*游戏详细描述*/
.product-game-info-desc {
    position: relative;
    /*top: 90px;*/
    /*top: -5px;*/
    /*left: 5%;*/
    font-size: 0.16rem;
    text-align: left;
    color: #7a7b7c;
    padding-left: 5%;
    padding-right: 5%;
    /*margin-top: 12%;*/
}

/* 更多按钮 */
.product-game-info-logo-btn-more {
    position: absolute;
    /*top: 120px;*/
    right: 0.05rem;
    bottom: 0.05rem;
    width: 0.8rem;
    height: auto;
    /*color: #7a7b7c;*/
    /*width: 85%;*/
    /*padding: 10px;*/
}

.product-game-hide-info {
    background: white;
    /*display: none;*/
    position: absolute;
    width: 100%;
    border-radius: 0 0 8px 8px;
    /*height: 0;*/
    /*height: auto;*/
}