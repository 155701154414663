/*#Product {*/
    /*!*margin-top: 64px;*!*/
    /*!*height: 300px;*!*/
    /*!*width: 100%;*!*/
    /*!*background: #f6f6f6;*!*/
    /*!*background: red;*!*/
    /*!*padding-bottom: 32px;*!*/
    /*!*min-height: calc(100vh - 64px - 190px);*!*/
/*}*/

/*.product-layout {*/
    /*width: 80%;*/
    /*height: 100%;*/
    /*margin: 0 auto;*/
    /*display: flex;*/
    /*justify-content: space-around;*/
    /*flex-wrap: wrap;   !*允许换行*!*/
/*}*/

#product {
    background: #f6f6f6;
}

.product-img-title-div {
    padding-top: 25px;
    padding-bottom: 5px;
    text-align: left;
    /*width: 100%;*/
}

/*手机端居中*/
@media screen and (max-width: 768px){
    .product-img-title-div {
        text-align: center;
    }
    .product-img-title-div > img {
        width: 200px;
    }
}