

#Jobs {
    /*margin-top: 64px;*/
    /*min-height: calc(100vh - 64px - 190px);*/
    /*background: red;*/
}

.jobs-bg {
    background: url("../../assets/images/jobs/recruit_780_default.png") no-repeat center center;
    background-size: cover;
    /*height: 780px;*/
    /*height: 0.78rem;*/
}

.jobs-title {
    color: white;
    /*font-size: 80px;*/
    font-size: 0.5rem;
    font-weight: bold;
    margin-top: 6%;
    /*padding-top: 2rem;*/
    /*padding-bottom: 2rem;*/
    /*margin-bottom: 2rem;*/
}

.jobs-bg-container {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-content: center;
    align-items: center;
}

/*职位推荐*/
.jobs-recommend-title {
    text-align: center;
    font-size: 0.5rem;
    font-weight: bold;
    padding-top: 0.3rem;
    color: black;
    /*margin-bottom: 0.35rem;*/
    padding-bottom: 0.35rem;
    /*margin-top: 0.35rem;*/
}