/*@bgUr*/

/*根容器*/
#MiniGamePlan {
    /*height: 45vh;*/
    /*height: 500px;*/
    /*background: #61dafb;*/
    /*width: 100%;*/
    /*position: relative;*/
    width: 100%;
    height: 100%;
    /*height*/
    /*height: auto;*/

    background: url("../../assets/images/cooperation/38-1.png") no-repeat center;
    background-size: cover;

    background-position-x: 55%;

    /*height: 300px*/
    /*height: 610px;*/

    /*overflow: hidden;*/
    /*background-size: cover;*/
    /*background-position: center center;*/
    /*height: 0;*/
    /*padding-top: 100%;*/
    /*min-height: 610px;*/
    /*background: url("../../images/");*/
    /*min-height: 800px;*/
}

.MiniGame-TEST {
    /*background: red;*/
    /*height: 100vh;*/
}

.MiniGamePlan-image-bg {
    /*width: 100%;*/

    /*max-width: 100%;*/

    width: 100%;

    background-size: cover;
    /*text-align: center;*/
    /*width: 100vw;*/
    /*min-height: 30vh;*/
    /*min-width: 50vw;*/
    /*width: 100%;*/
    /*height: 100vw;*/

    /*background-size: 100% 100%;*/
    /*height: auto;*/
}

.MiniGamePlan-container {
    width: 100%;
    /*height: 100px;*/
    position: absolute;
    top:0;
    left:0;
    /*background:red;*/
}

/*标题*/
.MiniGamePlan-title {
    color: white;
    /*font-size: 50px;*/
    font-size: 0.45rem;
    font-weight: bold;
    /*margin-top: 5%;*/
}

/*下面列表*/
.MiniGamePlan-item-list {
    list-style: none;
    /*background: #61dafb;*/
    /*margin-bottom: 50px;*/
    /*border: 2px solid red;*/
}

.MiniGamePlan-item-list>li {
    /*list-style: none;*/
    /*margin-top: 5px;*/
    /*background: #61dafb;*/
    /*margin-bottom: 50px;*/
    /*border: 2px solid red;*/
    height: 0.6rem;
    /*line-height: 1rem;*/
    /*height: 1rem;*/
    color: white;
    /*font-size: 35px;*/
    font-size: 0.35rem;
    /*padding-left: 10px;*/
    /*text-indent: 0.5rem;*/
    position: relative;
    vertical-align: middle;
    /*initial-letter: 2px;*/
}
.MiniGamePlan-item-list>li>img {
    max-width: 100%;
}

/*下面列表*/
/*.MiniGamePlan-item-list>li>span {*/

    /*!*list-style: none;*!*/
    /*!*vertical-align: middle;*!*/
    /*!*margin-bottom: 20px;*!*/
    /*color: white;*/
    /*line-height: 60px;*/
    /*height: 60px;*/
    /*!*margin-top: -20px;*!*/
    /*!*vertical-align: middle;*!*/
    /*text-align: center;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left:100px;*/
    /*font-size: 20px;*/
    /*!*padding-bottom: 20px;*!*/
/*}*/

/*#hexagon {*/
    /*width: 100px;*/
    /*height: 55px;*/
    /*background: white;*/
    /*position: relative;*/
    /*transform: scale(0.2, 0.21);*/
    /*!*display: inline-block;*!*/
/*}*/
/*#hexagon:before {*/
    /*content: "";*/
    /*position: absolute;*/
    /*top: -25px;*/
    /*left: 0;*/
    /*width: 0;*/
    /*height: 0;*/
    /*border-left: 50px solid transparent;*/
    /*border-right: 50px solid transparent;*/
    /*border-bottom: 25px solid white;*/
/*}*/
/*#hexagon:after {*/
    /*content: "";*/
    /*position: absolute;*/
    /*bottom: -25px;*/
    /*left: 0;*/
    /*width: 0;*/
    /*height: 0;*/
    /*border-left: 50px solid transparent;*/
    /*border-right: 50px solid transparent;*/
    /*border-top: 25px solid white;*/
/*}*/

@media screen and (max-width: 768px) {
    #MiniGamePlan-content {
        /*margin-top: -20% !important;*/
        align-items: start !important;
        padding-top: 3rem !important;
    }
}