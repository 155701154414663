
#PositionDesc {
    /*background: white;*/
    margin-left: 5%;
}

@media screen and (max-width: 768px) {
  #PositionDesc {
    margin-left: 0;
  }
}

.PositionDesc-title {
  /*height: 200px;*/
  /*padding-bottom: 10px;*/
  padding-bottom: 0.1rem;
  border-bottom: 1px solid #192c3e;
  position: relative;
}

.PositionDesc-title-text {
  /*height: 60px;*/
  height: 0.6rem;
  /*background: ;*/
  /*border-bottom: 1px solid #192c3e;*/
  /*font-size: 30px;*/
  font-size: 0.3rem;
  color: black;
  /*line-height: 60px;*/
  line-height: 0.6rem;
  /*padding-left: 10px;*/
  padding-left: 0.1rem;
  font-weight: bold;
}

.PositionDesc-info-text {
  /*font-size: 18px;*/
  font-size: 0.18rem;
  color: #888888;
  /*padding-left: 10px;*/
  padding-left: 0.1rem;
  /*padding-top: 10px;*/
  padding-top: 0.1rem;
  /*font-weight: bold;*/
}

.PositionDesc-info-email {
  margin-top: 5px;
  background: rgb(38, 145, 165);
  text-align: center;
  /*position: absolute;*/
  /*right: 20px;*/
  /*right: 0.2rem;*/
  /*bottom: 20px;*/
  /*bottom: 0.2rem;*/
  /*font-size: 30px;*/
  font-size: 0.24rem;
  color: white;
  /*line-height: 30px;*/
  line-height: 0.3rem;
  /*padding: 10px;*/
  padding: 0.1rem;
  /*border-radius: 8px;*/
  border-radius: 0.08rem;
}

.PositionDesc-left-title {
  /*border-left: 5px solid orange;*/
  border-left: 0.05rem solid orange;
  /*font-size: 20px;*/
  font-size: 0.2rem;
  font-weight: bold;
  color: black;
  /*padding-left: 8px;*/
  padding-left: 0.08rem;
  /*padding-left: 0.08rem;*/
  /*margin-top: 40px;*/
  margin-top: 0.4rem;
  /*background: green;*/
}

.PositionDesc-desc-text {
    /*color: black;*/
    color: #8d8d8d;
    /*font-size: 16px;*/
    font-size: 0.16rem;
    margin-top: 0.16rem;
    /*margin-top: 8px;*/
    /*line-height: 0.2rem;*/
    /*padding-top: 0.15rem;*/
    /*padding-bottom: 0.15rem;*/
    /*height: 0.24rem;*/
    /*line-height: 0.24rem;*/
}

.PositionDesc-desc-text > div {
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
}
