

#OurAdvantage {
    width: 100%;
    /*position: relative;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*flex-flow: column;*/
    height: 100%;

    /*margin-bottom: 30px;*/
    background: url("../../assets/images/cooperation/39-1.png") no-repeat center;
    background-size: cover;

    /*margin-top: 64px;*/
    padding-top: 64px;
}

#OurAdvantage-container {
    display: -ms-flex;
    display: flex;
    align-content: center;
    align-items: center;
    height: 100%;
}

/*.OurAdvantage-image-bg {*/
    /*width: 100%;*/
    /*!*text-align: center;*!*/
    /*!*width: 100vw;*!*/
    /*min-height: 30vh;*/
    /*!*min-width: 50vw;*!*/
    /*!*width: 100%;*!*/
    /*!*flex-grow: 0;*!*/
    /*!*height: 100vw;*!*/

    /*!*background-size: 100% 100%;*!*/
    /*!*height: auto;*!*/
/*}*/

.our-advantage-title-div {
    width: 100%;
    /*background: green;*/
    /*height: 30%;*/

    text-align: center;
    padding-bottom: 0.5rem;
    margin-top: -1rem;
    /*display: fl;*/
    /*display: -ms-flex;*/
}

.our-advantage-title-div>h2 {
    /*margin: 0 auto;*/
    color: white;
    /*font-size: 50px;*/
    font-size: 0.75rem;
    font-weight: bold;
    padding-top: 0.3rem;
    /*padding-top: 10px;*/
    /*margin-bottom: -10px;*/
}

.our-advantage-title-div > span {
    color: white;
    /*font-size: 30px;*/
    font-size: 0.35rem;
    /*text-indent: 2rem;*/
    text-align: center;
    /*width: 100%;*/
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;

    /*padding:5%*/
    /*padding-left: 5%;*/
    /*padding-right: 5%;*/
    /*padding-top: -5px;*/
    /*white-space: pre-wrap;*/
    /*text-wrap: ;*/
}

/**/
.our-advantage-item-content {
    display: flex;
    justify-content: space-between;
    align-content: center;
    /*margin-bottom: 20px;*/
    margin-bottom: 0.2rem;
}

.our-advantage-item-content>div:nth-child(1) {
    /*margin-left: 10px;*/
    margin-left: 0.1rem;
}

.our-advantage-item {
    /*width: 50%;*/
    /*width: 100%;*/
    /*max-width: 100%;*/
    /*height: 100px;*/
    /*height: 90%;*/
    /*min-height: 500px;*/
    background: white;
    /*margin-right: 10px;*/
    /*padding: 20px;*/
    /*border: 1px solid #888888;*/
    display: flex;
    align-items: center;
    /*align-content: ;*/
    flex-flow: column;
    /*box-shadow: 0 0 1px ;*/
    box-shadow:0 3px 3px #888888;
    /*box-shadow: ;*/
    /*padding: 20px;*/
    position: relative;
    /*padding-bottom: 1.2rem;*/
    /*margin-bottom: -1.2rem;*/
    text-align: center;
    min-height: 3.5rem;
}

.our-advantage-item>img {
    /*margin: 0.2rem auto;*/
    margin-top: 0.15rem;
    /*max-width: 80%;*/
    /*width: 7vw;*/
    /*width: 80%;*/
    height: auto;
    max-width: 100%;
    /*margin-top: 30px;*/
}

.our-advantage-item>h2 {
    font-size: 0.25rem;
    font-weight: bold;
}

.our-advantage-item>span {
    /*font-size: 1.5vw;*/
    width: 100%;
    font-size: 0.15rem;
    color: #888888;
    padding: 0.1rem;
    text-align: center;
}

@media screen and (max-width: 768px){

    #OurAdvantage {
        padding-top: 0;
    }
    .our-advantage-title-div {
        padding-bottom: 0.1rem;
    }
    .our-advantage-title-div > h2 {
        font-size: 0.56rem;
    }
    .our-advantage-title-div > span {
        font-size: 0.28rem;
    }
    .our-advantage-item {
        min-height: 4.7rem;
        transform: scale(0.9);
    }
    .our-advantage-item>img {
        /*max-width: 80%;*/
        width: 2rem;
        padding-top: 0.2rem;
    }
    .our-advantage-item>span {
        font-size: 0.25rem !important;
    }
    .our-advantage-item>h2 {
        margin-top: 0.1rem;
        font-size: 0.35rem !important;
    }
}

.our-advantage-item:before {
    content: "";
    width: 100%;
    height: 6px;
    background: rgb(255, 204, 0);
    position: absolute;
    top: 0;
    left: 0;
    transition: all .5s;
}

.our-advantage-item-1:before {
    background: rgb(62, 163, 241);
}

.our-advantage-item-2:before {
    background: rgb(218, 100, 232);
}

.our-advantage-item-3:before {
    background: rgb(253, 170, 70);
}

.our-advantage-item-4:before {
    background: rgb(0, 207, 205);
}

