/*.home-games {*/
    /*!*background: #61dafb;*!*/
    /*!*min-height: 400px;*!*/
    /*!*position: relative;*!*/
    /*background: white;*/
    /*width: 100%;*/
/*}*/


#home-games {
    background: white;
    width: 100%;
    margin-bottom: 0.2rem;
}

/*.home-games-container {*/
    /*height: 360px;*/
    /*!*background: red;*!*/
    /*width: 90%;*/
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*margin-left: -45%;*/
    /*margin-top: -180px;*/
/*}*/

.home-games-container-top {
    /*width: 100%;*/
    height: 0.8rem;
    /*background: #282c34;*/
    position: relative;

    text-align: center;
}

/*.home-games-container-center {*/
    /*height:-webkit-calc(100% - 140px);*/
    /*height:-moz-calc(100% - 140px);*/
    /*height:calc(100% - 140px);*/
    /*!*background: blue;*!*/
/*}*/

.home-games-container-bottom {
    /*height: 60px;*/
    /*background: yellow;*/
    /*height: 0.6rem;*/
    text-align: center;
    margin-top: 0.25rem;
    /*position: relative;*/
}

.home-games-container-top-span-game {
    /*display: table-cell;*/
    /*font-size: 72px;*/
    font-size: 0.72rem;
    color: rgba(255, 0, 0, 0.05);
    text-align: center;
    vertical-align: middle;
    /*position: absolute;*/
    /*margin: 30px auto;*/
    width: 220px;
    /*left: 50%;*/
    top: 0;
    /*top: 5%;*/
    /*margin-left: -110px;*/
    /*margin-top: -10px;*/
    font-weight: normal;
}

.home-games-container-top-span-our-game {
    /*font-size: 30px;*/
    font-size: 0.35rem;
    /*font-size: 35px;*/
    color: black;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    /*margin: 30px auto;*/
    width: 330px;
    left: 50%;
    top: 0;
    margin-left: -165px;
    /*border-bottom: 6px solid rgb(207, 18, 50) ;*/
    /*border-bottom-width: 80%;*/
}

/*.home-games-container-top-span-our-game:after {*/
    /*width: 100px;*/
    /*height: 5px;*/
    /*color:yellow;*/
    /*!*border: 2px solid red;*!*/
/*}*/

.home-games-container-top-span-underline {
    /*width: 86px;*/
    /*width: 86px;*/
    /*width: 330px;*/
    width: 1.4rem;
    height: 6px;
    background: rgb(207, 18, 50);
    border-radius: 7px;
    position: absolute;
    /*left: calc(50% - 175px);*/
    /*top:100%;*/
    left: 50%;
    /*margin-left: 0.43rem;*/
    /*margin-left: -43px;*/
    margin-left: -0.7rem;
    margin-top: 0.05rem;
    /*display: inline-block;*/
    /*font-size: 50px;*/
}