

#JoinProcess {

    width: 100%;
    height: 100%;

    background: url("../../assets/images/cooperation/41.png") no-repeat center;
    background-size: cover;
    /*height: 100vw;*/
    /*background-size: 100% 100%;*/
    /*position: relative;*/
    /*clear: both;*/
    /*min-height: 50vh;*/
}

#imgJoinProcessMobile {
    display: none;
    /*text-align: center;*/
    width: 100%;
    height: 100%;
    /*vertical-align: middle;*/
    /*margin-top: -20%;*/
    background: url("../../assets/images/cooperation/img_join_process_mobile.png") no-repeat center center;
    background-size: cover;
    background-position-y: -1rem;
}

@media screen and (max-width: 768px) {

    #imgJoinProcessMobile {
        display: block;
    }
}

/*背景*/
.JoinProcess-image-bg {
    width: 100%;
}

/*标题*/
.JoinProcess-title {
    /*position: absolute;*/
    color: white;
    /*font-size: 4vw;*/
    font-size: 0.7rem;
    /*width: 100%;*/
    font-weight: bold;
    text-align: center;
    margin-top: -2rem;
    /*top: 0;*/
    /*margin-top: 3%;*/
}

/*文字*/
.JoinProcess-info-desc {
    color: white;
    text-align: center;
    font-size: 30px;
    /*background: #888888;*/
    word-wrap: break-word;
    word-break: break-all;
    padding: 10px;
}

.JoinProcess-info-content {
    /*position: absolute;*/
    /*width: 100%;*/
    /*bottom: 0;*/
}

.join-process-image {
    /*width: 100%*/
    width: 100%;
    /*height: 100%;*/
    /*height: 194px;*/
    /*background: url("../../assets/images/cooperation/42.png") no-repeat center;*/
    /*background-size: cover;*/
}

/*.join-process-image-mobile {*/
    /*width: 100%;*/
    /*background-size: cover;*/
    /*!*background-position-y: -50%;*!*/
    /*height: auto;*/
    /*!*padding-bottom: 200px;*!*/
/*}*/
