
/*修改placeholder颜色*/
input::-webkit-input-placeholder {
    color: #ccc;
}
input::-ms-input-placeholder {
    color: #ccc;
}

#SearchBox {
    width: 85%;
    /*height: 0.3rem;*/
    /*background: black;*/
    position: relative;
    height: 100%;
}

.SearchBox-input {
    border: 2px solid;
    border-radius: 10px;
    height: 0.45rem;
    width: 100%;
    /*background: #fff;*/
    /*padding: 80px 80px;*/
    /*padding:20px;*/
    /*padding-top: 0.1rem;*/
    /*padding-bottom: 0.1rem;*/
    /*font-size: 0.35rem;*/
    font-size: 0.22rem;
    line-height: 0.45rem;
    padding-left: 0.4rem;
    /*padding: 100%;*/
}

.SearchBox-input:focus {
    outline: none;
}

.SearchBox-icon {
    position: absolute;
    left: 0.08rem;
    top: 0.08rem;
    font-size: 0.3rem;
    color: gray;
}

.SearchBox-search-button {
    /*width: 130px;*/
    border-radius: 0px 10px 10px  0px;
    text-align: center;
    background: black;
    position: absolute;
    right: 0;
    height: 0.44rem;
    line-height: 0.44rem;
    top: 0;
    color: white;
    font-size: 0.18rem;
    /*padding: 10px;*/
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    /*padding: 0.1rem;*/
    /*text*/
    cursor: pointer;
}