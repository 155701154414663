
#JobTypeView {
    /*margin-top: 64px;*/
    /*min-height: calc(100vh - 64px - 190px);*/
    background: white;
    /*text-align: center;*/
    /*left: 50%;*/
}

.JobTypeView-bg-container {
    background: url("../../assets/images/jobs/recruit_480_default.png") no-repeat center center;
    background-size: cover;
    /*min-height: 480px;*/
    max-height: 5rem;
}

.JobTypeView-bg-container-art {
    background: url("../../assets/images/jobs/recruit_480_art.png")
}

.JobTypeView-bg-container-develop {
    background: url("../../assets/images/jobs/recruit_480_develop.png")
}

.JobTypeView-bg-container-design {
    background: url("../../assets/images/jobs/recruit_480_game.png")
}

.JobTypeView-bg-container-market {
    background: url("../../assets/images/jobs/recruit_480_business.png")
}


.JobTypeView-top-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-flow: column;
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
}

.JobTypeView-position-container {
    /*background: rgb(246, 246, 246);*/
    /*height: 600px;*/
    /*margin-top: 100px;*/
    /*margin-left: 10%;*/
}

.JobTypeView-position-container-title {
    padding-top: 0.3rem;
}

.JobTypeView-position-container-title>span:nth-child(1) {
    border-left: 5px solid orange;
    font-size: 20px;
    /*font-size: 0.2rem;*/
    font-weight: bold;
    color: black;
    padding-left: 8px;
    /*padding-left: 0.08rem;*/
}

.JobTypeView-position-container-title>span:nth-child(2) {
    /*border-left: 5px solid orange;*/
    font-size: 14px;
    /*font-weight: bold;*/
    color: black;
    padding-left: 8px;
    margin-left: 30px;
    /*margin-left: 0.3rem;*/
    /*padding-left: 0.08rem;*/
    /*font-size: 0.14rem;*/
}

.JobTypeView-position-list-container {
    /*margin-top: 30px;*/
    margin-top: 0.3rem;
}