
.JobListItem {
    /*margin-top: 64px;*/
    /*height: 130px;*/
    /*height: 1.3rem;*/
    /*width: 1000px;*/
    width: 100%;
    /*background: red;*/
    /*border: 1px solid #192c3e;*/
    margin-bottom: 0.3rem;
    position: relative;
    padding-bottom: 0.1rem;
    box-shadow: 0 0.02rem 0.04rem #888888;
}

.JobListItem-title {
    /*height: 40px;*/
    height: 0.4rem;
    background: #ededed;
    /*border-bottom: 1px solid #192c3e;*/
    border-bottom: 1px solid #ededed;
    /*font-size: 20px;*/
    font-size: 0.2rem;
    color: black;
    /*line-height: 40px;*/
    line-height: 0.4rem;
    /*padding-left: 10px;*/
    padding-left: 0.1rem;
    font-weight: bold;
}

.JobListItem-info-text {
    /*font-size: 18px;*/
    font-size: 0.16rem;
    color: #888888;
    /*padding-left: 10px;*/
    padding-left: 0.1rem;
    /*padding-top: 10px;*/
    padding-top: 0.1rem;
    /*font-weight: bold;*/
}

.JobListItem-info-duty {
    /*font-size: 18px;*/
    font-size: 0.16rem;
    color: #888888;
    /*padding-left: 10px;*/
    padding-top: 0.1rem;
    /*padding-top: 10px;*/
    padding-left: 0.1rem;
    /*padding-bottom: 0.1rem;*/
    /*超过部分变省略号*/
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80%;
}

.JobListItem-info-arrow {
    color: rgb(62, 163, 243);
    position: absolute;
    /*right: 20px;*/
    right: 0.2rem;
    /*bottom: 20px;*/
    bottom: 0.2rem;
    /*font-size: 40px;*/
    font-size: 0.4rem;
}