#ProductDesc {
    /*margin-top: 64px;*/
    /*width: 100%;*/
    /*!*height: 300px;*!*/
    /*background: white;*/
    /*min-height: calc(100vh - 64px - 190px);*/
}

.ProductDesc-game-banner-image-div {
    display: flex;
    justify-content: center;
    align-items: center;
    /*width: 375px;*/
    /*width: 100%;*/
    /*height: auto;*/
    /*height: 定值;*/
}

.ProductDesc-game-banner-image {
    width: 100%;
    height: auto;
    background-size: cover;
}

.ProductDesc-title {
    display: flex;
    justify-content: flex-start;
    /*align-items:;*/
    /*background: red;*/
}

.ProductDesc-title-tags {
    display: flex;
    justify-content: flex-start;
}

.ProductDesc-title-text {
    /*font-size: 28px;*/
    font-size: 0.3rem;
    font-weight: bold;

    /*margin-top: 10px;*/
}

.ProductDesc-title-tags-item {
    padding: 0.05rem;
    background: black;
    /*font-size: 20px;*/
    font-size: 0.2rem;
    border-radius: 0.1rem;
    margin-right: 0.15rem;
    /*min-width: 100px;*/
    text-align: center;
    color: white;
    width: 30%;
    max-width: 1.35rem;
    /*margin-top: 5px;*/
    /*padding-bottom: 10px;*/
    margin-bottom: 0.1rem;
    /*font-size: 10px;*/
}

/*@media screen and (max-width: 768px) {*/
    /*.ProductDesc-title-tags-item {*/
        /*font-size: 1rem;*/
        /*border-radius: 5px;*/
        /*width: 32%;*/
        /*margin-right: 6px;*/
    /*}*/
    /*.ProductDesc-title-text {*/
        /*font-size: 1.8rem;*/
    /*}*/
/*}*/

.ProductDesc-title-tags>div:nth-child(1) {
    background: rgb(41, 193, 0);
}

.ProductDesc-title-tags>div:nth-child(2) {
    background: rgb(255, 168, 0);
}

.ProductDesc-title-tags>div:nth-child(3) {
    background: rgb(51, 51, 51);
}

/*介绍*/
.ProductDesc-introduce {
    /*height: 300px;*/
    /*background: red;*/
    /*margin-top: 78px;*/
    display: flex;
    justify-content: flex-start;
}

.ProductDesc-introduce-right {
    /*margin-left: 50px;*/
    width: 100%;
    height: auto;
    /*background: red;*/
}

.ProductDesc-introduce-right>h2 {
    font-size: 0.28rem;
    font-weight: bold;
    /*margin-top: -0.2rem;*/
    padding-top: 0;
}

.ProductDesc-introduce-right>p {
    font-size: 0.18rem;
    /*max-width: 600px;*/
    /*max-height: 0.01rem;*/
    /*text-overflow: ellipsis;*/
    /*-webkit-line-clamp: 2;*/
    /*max-height: 100px;*/
    /*line-*/
    /*display: inline-block;*/
    /*max-height: calc(100% - 0.3rem);*/

    /*height: calc(100% - 100px);*/
    /*height: 100px;*/


    /*超过7行截断*/
    /*display: -webkit-box;*/
    /*-webkit-box-orient: vertical;*/
    /*-webkit-line-clamp: 6;*/
    overflow: hidden;
}

.ProductDesc-banner-bottom {
    display: flex;
    justify-content: flex-start;
    margin-top: 90px;
    margin-bottom: 60px;
}

.ProductDesc-banner-bottom>img:nth-child(1) {
    margin-right: 50px;
}

