

#HomeBanner {
    background: rgb(240,242,245);
    /*height: 300px;*/
    height: auto;
    /*margin-top: -100px;*/
    /*background: red;*/
}

.HomeBanner-image-container {
    text-align: center;
}

.HomeBanner-image-container>img {
    max-width: 100%;
    height: auto;
}