/*import "~/slick-carousel/slick/slick.css";*/
/*import "~/slick-carousel/slick/slick-theme.css";*/

/*根容器高度100%*/
#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*.App-header {*/
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
/*}*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#main-container {
  margin-top: 64px;
  background: white;
  /*background: rgb(246, 246, 246);*/
  min-height: -moz-calc(100vh - 160px);
  min-height: -webkit-calc(100vh - 160px);
  min-height: calc(100vh  - 160px);
}
