
#Position {
    /*margin-top: 64px;*/
    /*min-height: calc(100vh - 64px - 190px);*/
    /*background: white;*/
}

.Position-bg-container {
    background: url("../../assets/images/jobs/recruit_230_default.png") no-repeat center;
    /*background-size: cover;*/
    /*min-height: 230px;*/
    background-size: 100% 100%;
}

.Position-bg-container-art {
    background: url("../../assets/images/jobs/recruit_230_art.png")
}

.Position-bg-container-develop {
    background: url("../../assets/images/jobs/recruit_230_develop.png")
}

.Position-bg-container-design {
    background: url("../../assets/images/jobs/recruit_230_game.png")
}

.Position-bg-container-market {
    background: url("../../assets/images/jobs/recruit_230_business.png")
}

.Position-top-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-flow: column;
    /*padding-top: 80px;*/
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /*padding-bottom: 80px;*/
}

@media screen and (max-width: 768px) {
    /*#PositionDesc {*/
        /*margin-left: 0;*/
    /*}*/
    #position-recommend {
        margin-left: 0 !important;
    }
}
