*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

html,body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "Microsoft YaHei","微软雅黑", Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-size: 100%;*/ /*兼容IE时最好写上*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@media only screen and (min-width: 481px){*/

  /*html {*/

    /*font-size: 94%!important; !* 15.04÷16=94% *!*/

  /*}*/

/*}*/

/*@media only screen and (min-width: 561px){*/
  /*html {*/
    /*font-size: 109%!important;!* 17.44÷16=109% *!*/
  /*}*/
/*}*/

/*@media only screen and (min-width: 641px){*/

  /*html {*/

    /*font-size: 125%!important;!* 20÷16=125% *!*/

  /*}*/
/*}*/
