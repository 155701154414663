
#company-profile {
    /*min-height: 340px;*/
    /*background: yellow;*/
    /*padding: 24px;*/
    /*position: relative;*/
    background: rgb(240, 242, 245);
    /*margin-top: 40px;*/
    /*width: 100%;*/
    padding-top: .25rem;
}

.company-desc {
    text-indent: 40px;
    text-indent: 0.4rem;
    font-size: 18px;
    font-size: 0.18rem;
    color: #a5a6a8;
}